/*
 * Edit Job Modal and Redux Connector
 */

import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import EditJobForm from './EditJobForm';
import { FormUtil } from '../common/FormUtil';
import { IinitialState } from '../../reducers';
import {
    addWorkOrdersToJob,
    addWorkOrdersToJobOnline,
    deleteJob,
    openJob,
    saveJob,
    saveJobOnline,
    setJobFormValues,
    setSelectedJob,
    toggleEditJobModal,
    toggleSearchFacilityModal,
    updateJob,
    updateJobFormValue
} from '../../actions/manageJobActions';
import { getFacilitiesByCustomer } from '../../actions/commonActions';
import { getOrderedFacilities } from '../../reducers/facilitiesReducer';
import { initialFacility, initialJob } from '../../reducers/initialState';
import { selectIsLoading } from '../../reducers/commonReducers';
import { selectSearchFacilities, selectNextJobNumber } from '../../reducers/manageJobReducer';
import { getIsMobile } from '../../reducers/configReducer';
import { jobTypesIdEnum } from '../../models-enums';
import { getSAPWOsFromSelection } from '../workOrder/ConfirmSelectJobModal';
import { IWorkOrder } from '../../models';
import { addCommissioningInstallBasesToJob, setTableFilter } from '../../actions/manageInventoryActions';
import { toggleConfirmSelectJobModal } from '../../actions/workOrderActions';

interface Iprops {
    colorButton: string;
    disabled?: boolean;
    modalClass?: string;
    selectedFacilityID?: string;
    secondModal?: boolean;
    fromWO?: boolean;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const { facilitiesByID } = state.facilities;
    const isMobile = getIsMobile(state);
    // get the customers selected in the formValues or from the selectedUser, then filter out all the facilities that do not belong to at least one of those customers.
    // const formValues: Partial<IeditJobFormValues> = state.manageJob.jobFormValues;
    const facilityID = ownProps.selectedFacilityID || state.manageJob.defaultFacilityID;
    const selectedItem = state.manageJob.jobsByID[state.manageJob.selectedJob.id] || initialJob;
    const className = `job-edit ${ownProps.secondModal ? 'second-modal' : ''}`;
    const title = ownProps.t('nsJob:saveModalTitle');
    const nextJobNumber = selectNextJobNumber(state);
    const sapJobMappings = state.sapJobMappingReducer.sapJobMappingsByID;

    let sapWOs: IWorkOrder[] = [];
    if (ownProps.fromWO) {
        sapWOs = getSAPWOsFromSelection(state);
    }
    const searchedFacilities =
        ownProps.fromWO && sapWOs.length > 0
            ? { [sapWOs[0].facility?.id || '']: sapWOs[0].facility || initialFacility }
            : selectSearchFacilities(state);
    const filteredFacilities = getOrderedFacilities(searchedFacilities);

    return {
        className,
        facilitiesByID,
        searchedFacilities,
        facilityOptions: FormUtil.convertToOptions(filteredFacilities),
        formValues: state.manageJob.jobFormValues,
        jobManage: state.manageJob,
        loading: selectIsLoading(state),
        selectedFacilityOption: state.manageJob.defaultFacilityID,
        selectedItem,
        show: state.manageJob.showEditJobModal,
        title,
        userID: state.user.id,
        online: state.offline.online,
        isMobile,
        nextJobNumber,
        selectedFacilityID: facilityID,
        fromSAPWO:
            ownProps.fromWO && sapWOs.length
                ? {
                      facility: sapWOs[0].facility?.id || '',
                      jobTypeID: sapWOs[0].suggestedJobTypeID as jobTypesIdEnum
                  }
                : undefined,
        sapWOs,
        sapJobMappings
    };
};

export default withTranslation('nsJob')(
    connect(mapStateToProps, {
        updateJob,
        saveJob,
        toggleModal: toggleEditJobModal,
        getFacilitiesByCustomer,
        updateFormValue: updateJobFormValue,
        setFormValues: setJobFormValues,
        onHide: toggleEditJobModal,
        deleteJob,
        toggleSearchFacilityModal,
        addWorkOrdersToJob,
        addCommissioningInstallBasesToJob,
        setSelectedJob,
        toggleConfirmSelectJobModal,
        openJob,
        saveJobOnline,
        addWorkOrdersToJobOnline,
        setTableFilter
    })(EditJobForm)
);
