import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { constants } from '../constants/constants';
import moment from 'moment';
import * as types from './actionTypes';
import { msalFetch } from '../components/auth/Auth-Utils';
import API from '../constants/apiEndpoints';
import {
    IinstallBasePart,
    IinstallBasePartPopulated,
    Ioption,
    Ipart,
    IquotePartCart,
    ThunkResult,
    IquotePartPopulated,
    IshoppingCartItem,
    IinstallBasePopulated
} from '../models';
import { beginAjaxCall } from './ajaxStatusActions';
import { orderBy } from 'lodash';
import { initialCartReducer, initialInstallBasePart, initialQuotePart } from '../reducers/initialState';
import { getSelectedJobID } from '../reducers/commonSelectors';
import { partTypeEnum } from '../models-enums';
import {
    quotePartsToCartItems,
    selectQuoteCartForJob,
    shoppingCartItemsToQuoteParts
} from '../components/parts/PartUtilities';

const uuidv4 = require('uuid/v4');

/**
 * find the existing cart if there is one, then update the message and items
 * @param message
 * @param items
 * @returns
 */
export function saveQuoteCart(message: string, items: IshoppingCartItem[]): ThunkResult<any> {
    return (dispatch, getState) => {
        const foundCart = selectQuoteCartForJob(getState());
        const selectedJobID = getSelectedJobID(getState(), {});
        let originalCart = { ...foundCart };

        if (foundCart.id.length === 0) {
            originalCart = { ...originalCart, isDeleted: true }; // rollback by deleting it
        }

        const updatedCart: IquotePartCart = {
            ...foundCart,
            id: foundCart.id || uuidv4(),
            quoteItems: shoppingCartItemsToQuoteParts(items, foundCart.quoteItems),
            jobID: foundCart.jobID || selectedJobID,
            message
        };
        const updatedCartForApi = {
            ...updatedCart,
            quoteItems: updatedCart.quoteItems.map((item: any) => {
                let { ...itemWithoutPart } = item;
                if (typeof itemWithoutPart.bundle !== 'string') {
                    itemWithoutPart.bundle = itemWithoutPart.bundle.toString();
                }
                return itemWithoutPart;
            })
        };
        const url = API.job.saveQuoteCart;
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: updatedCartForApi,
            url
        };
        dispatch({
            type: types.CHECKOUT_QUOTE_PARTS_SUCCESS,
            cart: updatedCart,
            meta: {
                offline: {
                    effect: {
                        axiosOptions,
                        message: 'save cart'
                    },
                    rollback: {
                        type: types.CHECKOUT_QUOTE_PARTS_FAILED,
                        cart: originalCart
                    }
                }
            }
        });
    };
}

/**
 *
 * @param parts
 * @returns partOptions sorted by the part description
 */
export const partsToOptions = (parts: unknown): Ioption[] => {
    if (Array.isArray(parts)) {
        const partOptions = parts.map((item: Ipart) => {
            return {
                value: item.id,
                label: `${item.number} - ${item.description}`,
                order: item.description
            };
        });
        return orderBy(partOptions, 'order');
    } else {
        return [];
    }
};

export const searchPartsForAsyncSelect = (
    search: string,
    type: partTypeEnum
): Promise<{ partOptions: Ioption[]; parts: Ipart[]; networkError: boolean }> => {
    const url = API.part.search;
    const axiosOptions: AxiosRequestConfig = {
        method: 'get',
        params: { number: search, type }
    };

    return msalFetch(url, axiosOptions)
        .then(data => {
            if (!data.data) {
                throw new Error('missing data');
            } else {
                return {
                    partOptions: partsToOptions(data.data.result),
                    parts: data.data.result,
                    networkError: false
                };
            }
        })
        .catch((error: any) => {
            constants.handleError(error, 'searchPartsForAsyncSelect', 'error', false);
            console.error('[searchPartsForAsyncSelect]:', error);
            return { partOptions: [], parts: [], networkError: true };
        });
};

export function saveInstallBasePart(
    installBasePart: IinstallBasePart,
    originalInstallBasePart: IinstallBasePartPopulated | IinstallBasePart,
    installBaseIDOverride?: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        if ('part' in originalInstallBasePart) {
            const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                part,
                ...cleanedOriginalInstallBasePart
            } = originalInstallBasePart;
            originalInstallBasePart = cleanedOriginalInstallBasePart;
        }
        if (!installBasePart.id || installBasePart.id.length === 0) {
            installBasePart = { ...installBasePart, id: uuidv4() };
            originalInstallBasePart = { ...installBasePart, isDeleted: true };
        }

        // If the part came from a virtual IB, then we need to override the installBaseID, otherwise the part will be saved to the wrong IB
        if (installBaseIDOverride) {
            installBasePart.installBaseID = installBaseIDOverride;
        }

        const url = API.part.saveInstallBasePart;
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: [installBasePart],
            url
        };
        dispatch({
            type: types.SAVE_INSTALL_BASE_PART,
            installBasePart,
            meta: {
                offline: {
                    effect: {
                        axiosOptions,
                        message: 'save install base part'
                    },
                    rollback: {
                        type: types.SAVE_INSTALL_BASE_PART,
                        installBasePart: originalInstallBasePart
                    }
                }
            }
        });
    };
}
export const addPart = (part: Ipart) => ({
    type: types.ADD_PART,
    part
});

export function savePartAsInstallBasePart(part: Ipart, installBaseID: string, quantity = 1): ThunkResult<any> {
    return (dispatch, getState) => {
        const { selectedJob } = getState().manageJob;

        const installBasePart: IinstallBasePartPopulated = {
            ...initialInstallBasePart,
            jobID: selectedJob.id,
            installBaseID,
            directPartID: part.id,
            directPartQuantity: quantity,
            part
        };
        dispatch(addPart(part));
        dispatch(saveInstallBasePart(installBasePart, initialInstallBasePart));
    };
}

export function getAllInstallBasePartsForFacility(facilityId: string): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { facilityId }
        };

        const url = API.part.GetAllInstallBasePartsForFacility;

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('error getting all parts users');
                } else {
                    dispatch({
                        type: types.GET_ALL_INSTALL_BASE_PARTS_SUCCESS,
                        payload: data.data,
                        updateDate: moment().unix()
                    });
                    return data;
                }
            })
            .catch(error => {
                dispatch({ type: types.GET_ALL_INSTALL_BASE_PARTS_FAILED });
                constants.handleError(error, 'get all parts');
                console.error('[getAllInstallBasePartsForFacility]:', error);
                throw error;
            });
    };
}

export function getAllParts(preferServer = false): ThunkResult<any> {
    return (dispatch, getState) => {
        const now = moment();
        const diff = now.diff(moment(getState().syncStatus.allPartsUpdated, 'X'), 'minutes');
        const shouldUpdate = diff > constants.cacheAllPartsMinutes;

        if (shouldUpdate === false && preferServer === false) {
            return Promise.resolve();
        }

        const url = API.part.getAllParts;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { includeDeleted: true },
            url
        };

        dispatch({
            type: types.GET_ALL_PARTS,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'Get All Parts' },
                    commit: { type: types.GET_ALL_PARTS_SUCCESS, updateDate: moment().unix() }
                }
            }
        });

        // return msalFetch(url, axiosOptions)
        //     .then((data: AxiosResponse<any>) => {
        //         if (!data.data) {
        //             throw new Error('error getting all parts users');
        //         } else {
        //             dispatch({
        //                 type: types.GET_ALL_PARTS_SUCCESS,
        //                 payload: data.data,
        //                 updateDate: moment().unix()
        //             });
        //             return data;
        //         }
        //     })
        //     .catch(error => {
        //         dispatch({ type: types.GET_ALL_PARTS_FAILED });
        //         constants.handleError(error, 'get all parts');
        //         console.error('[getAllParts]:', error);
        //         throw error;
        //     });
    };
}

export function deleteInstallBasePart(installBasePartID: string): ThunkResult<any> {
    return (dispatch, getState) => {
        const installBasePartsByID = getState().parts.installBasePartsByID;
        const originalInstallBasePart = installBasePartsByID[installBasePartID];
        const deletedInstallBasePart = {
            ...originalInstallBasePart,
            isDeleted: true
        };
        dispatch(saveInstallBasePart(deletedInstallBasePart, originalInstallBasePart));
    };
}

export function addPartToCart(part: Ipart, installBaseID?: string, bundle?: number): ThunkResult<any> {
    return (dispatch, getState) => {
        let quantity = 1;

        if (part.quantity !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            quantity = part.quantity!;
        }

        const item: IquotePartPopulated = {
            ...initialQuotePart,
            partID: part.id,
            id: uuidv4(),
            part,
            quantity,
            installBaseID,
            bundle: bundle || 1
        };

        dispatch({
            type: types.ADD_TO_CART_QUOTE_PARTS,
            item,
            jobID: getState().manageJob.selectedJob.id
        });
        dispatch(addPart(part));
    };
}

export function deleteQuoteItemFromQuoteCart(itemID: string): ThunkResult<any> {
    return (dispatch, getState) => {
        const quoteCart = selectQuoteCartForJob(getState());
        const quoteItemToDelete = quoteCart.quoteItems.find(x => x.id === itemID);
        dispatch({
            type: types.DELETE_QUOTE_ITEM_FROM_QUOTE_CART,
            item: quoteItemToDelete,
            quoteCartId: quoteCart.id
        });
    };
}

/**
 * take the cart for this job (create one if needed), update the items to be shoppingCart items
 * set this cart as the selectedCart
 * @returns
 */
export function setSelectedCart(): ThunkResult<any> {
    return (dispatch, getState) => {
        const foundCart = selectQuoteCartForJob(getState());

        if (foundCart) {
            const existingCartItems = quotePartsToCartItems(foundCart.quoteItems);
            const existingCart = {
                addedIDs: Object.keys(existingCartItems),
                message: foundCart.message,
                itemsByID: existingCartItems,
                quantity: existingCartItems.quantity
            };
            dispatch({
                type: types.SET_CART_QUOTE_PARTS,
                cart: existingCart
            });
        } else {
            const newCart = {
                ...initialCartReducer
            };
            dispatch({
                type: types.SET_CART_QUOTE_PARTS,
                cart: newCart
            });
        }
    };
}
export const setSelectedInstallBasePartID = (id: string) => ({
    type: types.SET_SELECTED_INSTALL_BASE_PART_ID,
    id
});

export const clearSelectedInstallBasePartID = () => ({
    type: types.CLEAR_SELECTED_INSTALL_BASE_PART_ID
});

export const setPartFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_PART,
    formValues
});

export const updatePartFormValue = (formValues: { [key: string]: any }) => ({
    type: types.UPDATE_FORM_VALUES_PART,
    formValues
});
