import React, { useLayoutEffect, useMemo } from 'react';
import { Editor } from '@tinymce/tinymce-react';

// TinyMCE so the global var exists
import 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin';

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/help/js/i18n/keynav/en';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis';

// Content styles, including inline UI like fake cursors
import 'tinymce/skins/content/default/content';
import 'tinymce/skins/ui/oxide/content';
import tinymce from 'tinymce/tinymce';
import uuidv4 from 'uuid/v4';

interface IProps {
    content: string;
    setContent: (content: string) => void;
}

const RTE = ({ content, setContent }: IProps) => {
    const id = useMemo(() => uuidv4(), []);

    const listener = () => {
        const editor = tinymce.get(id);
        const editorContent = editor?.getContent();
        setContent(editorContent || '');
    };

    useLayoutEffect(() => {
        const editor = tinymce.get(id);
        const editorContent = editor?.getContent();

        if (editorContent !== content) {
            // I don't have the energy to figure out why it doesn't work without the timeout.

            setTimeout(() => {
                editor?.setContent(content);
            }, 100);
        }
    }, [content]);

    return (
        <Editor
            id={id}
            init={{
                promotion: false,
                plugins: 'lists link fontsize',
                toolbar: 'link unlink bold italic underline bullist numlist fontsize',
                menubar: false,
                relative_urls: false,
                branding: false,
                setup(editor) {
                    editor.on('keyup', listener);
                }
            }}
            apiKey="gpl"
        />
    );
};

export default RTE;
