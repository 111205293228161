import * as React from 'react';

// MSAL imports
import { withMsal, WithMsalProps } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { Iuser, IsyncStatusReducer, AppSyncItem } from './models';
import { Redirect, Route, BrowserRouter as Router, Switch, RouteComponentProps } from 'react-router-dom';
import ReduxToastr, { toastr } from 'react-redux-toastr';

import Header from './components/header/Header';
import Loading from './components/common/Loading';
import Login from './components/auth/Login';
import { Outbox } from '@redux-offline/redux-offline/lib/types';
import { ToastrState } from './typings/react-redux-toastr';
import { TrackJS } from 'trackjs';
import TwoPaneLayout from './components/common/TwoPaneLayout';
import {
    checkForMissingData,
    checkSyncStatus,
    closeAllModals,
    downloadAppState,
    initialAppSyncV2,
    offlineStatusOverrideOn,
    saveAppLog,
    setInitialAppSyncStatus
} from './actions/commonActions';
import { connect } from 'react-redux';
import { constants } from './constants/constants';
import { initialToastrState } from './reducers/initialState';
import moment from 'moment';
import { userLogoutSessionOnly, userLogout, forceUserLogout } from './actions/userActions';
import { Button } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { IinitialState } from './reducers';
import { initWorker } from './actions/workerActions';
import { selectIsLoading } from './reducers/commonReducers';
import Mixpanel from './helpers/Mixpanel';
import { debugMSAL } from './components/auth/Auth-Utils';
import { langOption } from './i18n';
import MobileProvider from './components/common/MobileProvider';
import { AppSyncItemStatus } from './models-enums';
import msalInstance from './components/auth/MsalInstance';

window.addEventListener('beforeinstallprompt', e => {
    console.log('[beforeinstallprompt]: install prompt triggered');
});

const SocialSignup = () => (
    <h3 style={{ padding: '20px' }}>Please contact support in order to sign up for an account.</h3>
);
// const ErrorPage = (error: any) => <h3>Error: {error}</h3>;
const NoMatch = ({ location }: any) => {
    console.error(`[NoMatch]: no match for route "${location.pathname}"`);
    return (
        <h3 style={{ padding: '20px' }}>
            <code>{location.pathname}</code> does not exist
        </h3>
    );
};

interface Iprops extends RouteComponentProps<{}> {
    user: Iuser;
    modalOn: boolean;
    outbox: Outbox;
    syncStatus: IsyncStatusReducer;
    checkSyncStatus: typeof checkSyncStatus;
    toastr: ToastrState;
    userLogoutSessionOnly: typeof userLogoutSessionOnly;
    userLogout: typeof userLogout;
    downloadAppState: typeof downloadAppState;
    offlineStatusOverrideOn: typeof offlineStatusOverrideOn;
    saveAppLog: (t: TFunction) => Promise<any>;
    online: boolean;
    initWorker: typeof initWorker;
    initComplete: boolean;
    pca: PublicClientApplication;
    closeAllModals: typeof closeAllModals;
    initialAppSyncV2: typeof initialAppSyncV2;
    forceUserLogout: typeof forceUserLogout;
    downloadSpeed: number | undefined;
    setInitialAppSyncStatus: typeof setInitialAppSyncStatus;
    checkForMissingData: typeof checkForMissingData;
}

interface State {
    initialSyncError: boolean; // old way
    redirectToSocialSignup: boolean;
    httpTimeoutCounter: number;

    initialAppSyncError: boolean; // new way
    retryingAppSync: boolean;
    checkingForMissingData: boolean;
}

class App extends React.Component<Iprops & WithTranslation & WithMsalProps, State> {
    constructor(props: Iprops & WithTranslation & WithMsalProps) {
        super(props);
        this.state = {
            initialSyncError: false,
            redirectToSocialSignup: false,
            httpTimeoutCounter: 0,
            initialAppSyncError: false,
            retryingAppSync: false,
            checkingForMissingData: false
        };

        // When the app starts up, check local storage for any existing account, then set it
        // If there is no active account, getting a token will fail
        debugMSAL(this.props.msalContext.instance.getAllAccounts());
        if (this.props.msalContext.instance.getAllAccounts().length > 0) {
            if (this.props.msalContext.instance.getAllAccounts().length > 1) {
                toastr.error(
                    this.props.t('toastMessage:error'),
                    this.props.t('toastMessage:multipleAccounts'),
                    constants.toastrError
                );
                this.props.userLogoutSessionOnly();

                return;
            }
            this.props.msalContext.instance.setActiveAccount(this.props.msalContext.instance.getAllAccounts()[0]);
        }

        // Set the instance in Auth-Utilis
        debugMSAL(this.props.msalContext.instance);
    }

    componentDidMount() {
        console.log('Component Did Mount: App');
        this.props.closeAllModals();
        this.props.initWorker();

        this.doubleCheckOfflineStatus();
        this.setState({ initialAppSyncError: this.showAppSyncFailure(), retryingAppSync: false });

        // If the user refreshes the browser while we are loading the initial sync, we need to reset the status of the items back to in queue
        // So they can be restarted

        if (this.props.syncStatus.initialAppSyncList.length > 0) {
            this.props.syncStatus.initialAppSyncList.forEach((item: AppSyncItem) => {
                if (item.status === AppSyncItemStatus.inProgress) {
                    this.props.setInitialAppSyncStatus(item.name, AppSyncItemStatus.inQueue);
                }
            });
        }

        // this.doubleCheckInitialSyncStatus();

        document.addEventListener('initialSyncError', this.handleInitialSyncError, false);
        document.addEventListener('missingUser', this.handleRedirectToSignup, false);
        document.addEventListener('newVersionAvailable', this.handleNewVersion, false);
        document.addEventListener('missingPersistedProducts', this.handleMissingProducts, false);
        document.addEventListener('httpTimeout', this.handleHttpTimeout, false);
        document.addEventListener('criticalOutboxError', this.handleCriticalOutboxError, false);
        document.addEventListener('criticalOutboxErrorWithToast', this.handleCriticalOutboxErrorWithToast, false);

        document.addEventListener('startUserLogoutSessionOnly', this.props.userLogoutSessionOnly, false);
        document.addEventListener('startUserLogout', this.handleLogout, false);
        document.addEventListener('forceUserLogout', this.forceLogout, false);

        if (this.props.user.email.length) {
            TrackJS.configure({
                userId: this.props.user.email,
                version: process.env.REACT_APP_VERSION
            });
            Mixpanel.track('User Launched App');
            Mixpanel.identify(this.props.user.id);
            Mixpanel.people.set({
                $email: this.props.user.email,
                USER_ID: this.props.user.id
            });
        }

        moment.locale(this.props.i18n.language);
    }

    componentWillUnmount() {
        document.removeEventListener('initialSyncError', this.handleInitialSyncError, false);
        document.removeEventListener('startUserLogout', this.handleLogout, false);

        document.removeEventListener('missingUser', this.handleRedirectToSignup, false);
        document.removeEventListener('newVersionAvailable', this.handleNewVersion, false);
        document.removeEventListener('httpTimeout', this.handleHttpTimeout, false);
        document.removeEventListener('criticalOutboxError', this.handleCriticalOutboxError, false);
        document.removeEventListener('criticalOutboxErrorWithToast', this.handleCriticalOutboxErrorWithToast, false);
        document.removeEventListener('missingPersistedProducts', this.handleMissingProducts, false);
        document.removeEventListener('startUserLogoutSessionOnly', this.props.userLogoutSessionOnly, false);
        document.removeEventListener('forceUserLogout', this.forceLogout, false);
    }

    componentDidUpdate(prevProps: Iprops) {
        if (this.props.user.language !== prevProps.user.language) {
            if (this.props.user.language) {
                const lang = langOption(this.props.user.language);
                this.props.i18n.changeLanguage(lang);
            }
        }

        if (
            JSON.stringify(this.props.syncStatus.initialAppSyncList) !==
            JSON.stringify(prevProps.syncStatus.initialAppSyncList)
        ) {
            // Check if we should show the error screen
            this.setState({ initialAppSyncError: this.showAppSyncFailure() });
        } else {
            const showError = this.showAppSyncFailure();

            // If we're not showing the error screen, and all items are in queue, and we're not already retrying, then retry syncing the remaining items
            if (
                showError === false &&
                this.props.syncStatus.initialAppSyncList.length > 0 &&
                this.props.syncStatus.initialAppSyncList.every(
                    (item: AppSyncItem) => item.status === AppSyncItemStatus.inQueue
                ) &&
                !this.state.retryingAppSync
            ) {
                // Don't try syncing if the user is not authenticated
                if (msalInstance.msalApp.getActiveAccount() && this.props.user.isAuthenticated) {
                    this.setState({ retryingAppSync: true });
                    this.props.initialAppSyncV2(true);
                }
            }
        }

        // If the user is authenticated, and they are not syncing data, check if we are missing any critical data that needs to be refetched.
        if (
            !this.state.checkingForMissingData &&
            this.props.user &&
            this.props.user.isAuthenticated &&
            !this.state.retryingAppSync &&
            this.props.syncStatus.initialAppSyncList.length === 0
        ) {
            this.props.checkForMissingData();
            this.setState({ checkingForMissingData: true });
        }
    }

    /*
     * doubleCHeckInitialSyncStatus
     * first check if there has been an intial sync and the user is authenticated, if not then go ahead and retry the initial sync
     * if initial sync has not been successful after 10 minutes, then show the error screen
     */
    doubleCheckInitialSyncStatus = () => {
        const msalAccounts = this.props.msalContext.instance.getAllAccounts();

        if (this.props.user.isAuthenticated && msalAccounts.length > 0) {
            this.props.checkSyncStatus(true);
        } else if (this.props.user.isAuthenticated && msalAccounts.length === 0) {
            console.error(
                '[doubleCheckInitialSyncStatus]: user is authenticated, but there are no msal accounts; doing nothing so they can continue to work offline'
            );
        }

        setTimeout(() => {
            if (
                !this.props.initComplete &&
                !this.props.syncStatus.allProductsUpdated &&
                !this.props.syncStatus.measurementPointResultsUpdated
            ) {
                console.error(
                    '[doubleCheckInitialSyncStatus]: failed double check of initial sync, asking user to retry'
                );
                this.setState({ initialSyncError: true });
            }
        }, constants.httpTimeoutInitialSync + 8000);
    };

    doubleCheckOfflineStatus = () => {
        if (window.navigator.onLine) {
            window.dispatchEvent(new CustomEvent('online'));
        } else {
            window.dispatchEvent(new CustomEvent('offline'));
        }
    };

    /*
     * try to save the outbox
     * if that does not work then save locally
     */
    handleCriticalOutboxError = () => {
        this.props.saveAppLog(this.props.t).catch(() => {
            this.props.downloadAppState();
        });
    };

    handleCriticalOutboxErrorWithToast = () => {
        toastr.error(this.props.t('toastMessage:error'), this.props.t('toastMessage:criticalOutboxError'), {
            ...constants.toastrError,
            timeOut: 0
        });
        this.props.saveAppLog(this.props.t).catch(() => {
            this.props.downloadAppState();
        });
    };

    handleHttpTimeout = () => {
        this.setState({
            httpTimeoutCounter: this.state.httpTimeoutCounter + 1
        });
        setTimeout(() => {
            this.setState({ httpTimeoutCounter: 0 });
        }, constants.httpOfflineConfirmCountResetTimeout);
        if (this.state.httpTimeoutCounter >= constants.httpOfflineConfirmCount) {
            const { t } = this.props;
            const toastrConfirmOptions = {
                onOk: () => {
                    this.setState({ httpTimeoutCounter: 0 });
                    this.props.offlineStatusOverrideOn();
                },
                onCancel: () => {
                    this.setState({ httpTimeoutCounter: 0 });
                },
                okText: t('common:goOffline'),
                cancelText: t('common:cancel')
            };
            toastr.confirm(t('toastMessage:httpTimeoutOfflineConfirm'), toastrConfirmOptions);
        }
    };

    handleRedirectToSignup = () => {
        // this.props.history.push('/social_signup');
        this.setState({ redirectToSocialSignup: true }, () => {
            this.setState({ redirectToSocialSignup: false });
        });
    };

    handleLogout = () => {
        this.props.userLogout(this.props.t);
    };

    forceLogout = () => {
        this.props.forceUserLogout();
    };

    // sometimes the browser deletes the indexDB store of products.  This helps recover gracefully.
    handleMissingProducts = () => {
        this.props.checkSyncStatus(true);
    };

    handleInitialSyncError = () => {
        this.setState({ initialSyncError: true });
    };

    PrivateRoute = ({ component: Component, ...rest }: any) => {
        const { user } = this.props;
        const authenticated = user.isAuthenticated && user.id.length > 0; // do not check for msal account here because offline operation will not allow the user to refresh the token
        return (
            <Route
                {...rest}
                render={(props: any) =>
                    authenticated ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: { from: props.location }
                            }}
                        />
                    )
                }
            />
        );
    };

    handleNewVersion = () => {
        const { outbox } = this.props;
        if (outbox && outbox.length > 0) {
            setTimeout(() => {
                console.info('[handleNewVersion]: new version available, waiting for outbox before updating');
                this.handleNewVersion();
            }, 7000);
        } else {
            toastr.warning('Installing Update', 'Please wait...', {
                ...constants.toastrWarning,
                timeOut: 5000
            });
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        }
    };

    /*
     * shouldShowFullScreenLoading
     * if we are getting part 1 of the initial sync
     * if we are not on the jobs view and have complete completed the full initial sync
     */
    shouldShowFullScreenLoading = () => {
        const hasActiveMsalAccount = this.props.msalContext.instance.getActiveAccount();

        if (this.props.user && this.props.user.isAuthenticated && hasActiveMsalAccount) {
            let showLoading = false;

            if (this.props.syncStatus.initialAppSyncList.length > 0) {
                showLoading = true;
            }

            return showLoading;
        }

        return false;
    };

    showAppSyncFailure = () => {
        let showError = false;

        const { initialAppSyncList } = this.props.syncStatus;

        if (initialAppSyncList.length > 0) {
            initialAppSyncList.forEach((item: AppSyncItem) => {
                if (item.status === AppSyncItemStatus.failed) {
                    showError = true;
                }
            });
        }

        return showError;
    };

    restartAppSync = () => {
        this.setState({ initialAppSyncError: false, retryingAppSync: true });
        this.props.initialAppSyncV2(true);
    };

    render() {
        const { PrivateRoute } = this;
        if (this.state.redirectToSocialSignup) {
            return (
                <Router>
                    <Redirect to={'/social_signup'} />
                </Router>
            );
        }

        if (this.state.initialAppSyncError) {
            return (
                <div className="app" style={{ padding: '30px' }}>
                    <h4>Initial downloading of data failed. Please check your internet connection and try again.</h4>
                    {this.props.syncStatus.initialAppSyncList.map((item: AppSyncItem) => {
                        return <div key={item.name}>- {item.displayName} failed.</div>;
                    })}
                    <Button
                        onClick={() => {
                            this.restartAppSync();
                        }}
                    >
                        Retry
                    </Button>
                </div>
            );
        }

        return (
            <div className="app">
                <div className={this.props.modalOn ? 'modal-background' : ''}>
                    <MobileProvider />
                    <Loading
                        show={this.shouldShowFullScreenLoading()}
                        message={'Loading app data, Please wait...'}
                        syncItems={this.props.syncStatus.initialAppSyncList}
                        downloadSpeed={this.props.downloadSpeed}
                        restartAppSync={this.restartAppSync}
                    />
                    <Router>
                        <div className="main-body-content">
                            <Route path="*" component={Header} />
                            <Switch>
                                <Route exact path="/" component={Login} />
                                <Route exact path="/social_signup" component={SocialSignup} />
                                <PrivateRoute path="/devices" component={TwoPaneLayout} />
                                <PrivateRoute path="/suggested_parts" component={TwoPaneLayout} />
                                <PrivateRoute path="/locations" component={TwoPaneLayout} />
                                <PrivateRoute path="/jobs" component={TwoPaneLayout} />
                                <PrivateRoute path="/job_comments" component={TwoPaneLayout} />
                                <PrivateRoute path="/maintenance" component={TwoPaneLayout} />
                                <PrivateRoute path="/repair" component={TwoPaneLayout} />
                                <PrivateRoute path="/sap" component={TwoPaneLayout} />
                                <PrivateRoute path="/unassigned-sap-workorders" component={TwoPaneLayout} />
                                <PrivateRoute path="/commission" component={TwoPaneLayout} />
                                <PrivateRoute path="/warranty" component={TwoPaneLayout} />
                                <PrivateRoute path="/simple_list" component={TwoPaneLayout} />
                                <PrivateRoute path="/quote_part_cart" component={TwoPaneLayout} />

                                <Route component={NoMatch} />
                            </Switch>
                            <ReduxToastr
                                position={'top-center'}
                                preventDuplicates={process.env.NODE_ENV === 'production'}
                                getState={() => this.props.toastr || initialToastrState}
                            />
                        </div>
                    </Router>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: any) => {
    return {
        user: state.user,
        loading: selectIsLoading(state),
        outbox: state.offline.outbox,
        syncStatus: state.syncStatus,
        initComplete: state.syncStatus.isInitialSyncComplete,
        toastr: state.toastr,
        online: state.offline.online,
        modalOn: state.manageJob.jobFinalCheckModal && !state.config.isMobile,
        downloadSpeed: state.syncStatus.downloadSpeed
    };
};

export default withTranslation('')(
    withMsal(
        connect(mapStateToProps, {
            checkSyncStatus,
            userLogoutSessionOnly,
            userLogout,
            downloadAppState,
            offlineStatusOverrideOn,
            saveAppLog,
            initWorker,
            closeAllModals,
            initialAppSyncV2,
            forceUserLogout,
            setInitialAppSyncStatus,
            checkForMissingData
        })(App)
    )
);
