/*
 * Search Inventory Table Form
 * searching inventory has some special dropdowns for locations
 */

import * as React from 'react';

import { AbstractControl, FieldConfig, FormGenerator, FormGroup, GroupProps, Observable } from 'react-reactive-form';
import { Button, Col, Row } from 'react-bootstrap';
import {
    Ibuilding,
    Ifacility,
    Ifloor,
    IinstallBasePopulated,
    Ijob,
    Ilocation,
    Ioption,
    IproductInfo,
    ItableFiltersReducer
} from '../../models';
import { debounce, find, forEach, omit } from 'lodash';
import { jobTypesIdEnum, inventoryStatusFilterEnum, workOrderStatusEnum } from '../../models-enums';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormUtil } from '../common/FormUtil';
import { InventoryActionButtonContainer } from './InventoryActionButtonContainer';
import { RouteComponentProps } from 'react-router';
import { TFunction } from 'i18next';
import { constants } from '../../constants/constants';
import { setTableFilter } from '../../actions/manageLocationActions';
import { getJobDependenciesHelper } from '../../actions/commonActions';
import { setSelectedCart } from '../../actions/partsActions';
import { setFilterConfig } from '../../actions/manageInventoryActions';

const defaultBuildingID = { value: 'unallocated', label: 'Unallocated' };

interface IstateChanges extends Observable<any> {
    next: () => void;
}
interface AbstractControlEdited extends AbstractControl {
    stateChanges: IstateChanges;
}

interface Iprops extends RouteComponentProps<{}> {
    handleSubmit: any;
    loading: boolean;
    showSearchButton?: boolean;
    colorButton: string;
    t: TFunction;
    facility: Ifacility;
    buildings: Ibuilding[];
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    productInfo: IproductInfo;
    startAddWorkOrders: () => void;
    clearTableFilter: () => void;
    setFilterConfig: typeof setFilterConfig;
    installBasesPopulated: IinstallBasePopulated[];
    isWorkOrderTypeJob: boolean;
    isAddWorkOrderMode: boolean;
    isJobDownloaded: boolean;
    getJobDependenciesHelper: typeof getJobDependenciesHelper;
    selectedJob: Ijob;
    hasSelection: boolean;
    resetSelection: () => void;
    setSelectedCart: typeof setSelectedCart;
}

interface Istate {
    fieldConfig: FieldConfig;
}

export default class SearchInventoryTableForm extends React.Component<Iprops, Istate> {
    public searchForm: FormGroup | any;
    private subscription: any;
    private showBtn: boolean;

    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfig: { controls: {} }
        };
        this.showBtn = typeof this.props.showSearchButton === 'undefined' ? true : this.props.showSearchButton;
        this.props.setTableFilter({
            page: 0,
            search: undefined,
            building: null,
            floor: null,
            location: null,
            room: null,
            status: undefined,
            mainCategory: undefined,
            hideVirtualToggle: true
        });
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        if (params && params.get('switch_job')) {
            this.props.clearTableFilter();
            this.props.history.replace('/devices');
            setTimeout(() => {
                this.setState({
                    fieldConfig: FormUtil.translateForm(this.buildSearchControls(), this.props.t)
                });
            }, 100);
        } else {
            this.setState({
                fieldConfig: FormUtil.translateForm(this.buildSearchControls(), this.props.t)
            });
        }
        this.checkForDownloadedJob();
        this.props.setFilterConfig(FormUtil.translateForm(this.buildSearchControls(), this.props.t));
    }

    componentDidUpdate(prevProps: Iprops) {
        if (JSON.stringify(prevProps.buildings) !== JSON.stringify(this.props.buildings)) {
            this.setState({
                fieldConfig: FormUtil.translateForm(this.buildSearchControls(), this.props.t)
            });
        }
        if (prevProps.tableFilters.showAdditionalFilters !== this.props.tableFilters.showAdditionalFilters) {
            this.setState({
                fieldConfig: FormUtil.translateForm(this.buildSearchControls(), this.props.t)
            });
        }
        if (prevProps.isAddWorkOrderMode !== this.props.isAddWorkOrderMode) {
            this.setState({
                fieldConfig: FormUtil.translateForm(this.buildSearchControls(), this.props.t)
            });
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    checkForDownloadedJob = () => {
        if (this.props.isJobDownloaded === false) {
            this.props.getJobDependenciesHelper([this.props.selectedJob], false);
        } else {
            this.props.setSelectedCart();
        }
    };

    subscribeToValueChanges = () => {
        forEach(this.state.fieldConfig.controls, (input: any, key) => {
            if (key.indexOf('$field') !== -1) {
                return;
            }
            this.subscription = this.searchForm.get(key).valueChanges.subscribe((value: any) => {
                this.onValueChanges(value, key);
            });
        });
    };

    buildSearchControls = (): FieldConfig => {
        const {
            building,
            floor,
            location,
            room,
            measurementPointListResultStatus,
            workOrderResultStatus,
            mainCategory,
            showAdditionalFilters,
            search,
            system
        } = this.props.tableFilters;

        const disabled = false;
        const foundBuilding = find(this.props.buildings, (b: Ibuilding) => {
            return building && b.id === building.value;
        });
        if (building && !foundBuilding) {
            this.props.clearTableFilter();
        }
        let floorOptions;
        let locationOptions;
        let roomOptions;

        const buildingOptions = FormUtil.convertToOptions([...this.props.buildings, defaultBuildingID]);

        if (foundBuilding && typeof foundBuilding === 'object' && 'floors' in foundBuilding && foundBuilding.floors) {
            floorOptions = FormUtil.convertToOptions(foundBuilding.floors);
            if (floor) {
                const foundFloor = find(foundBuilding.floors, (f: Ifloor) => {
                    return f.id === floor.value;
                });
                if (foundFloor) {
                    locationOptions = FormUtil.convertToOptions(foundFloor.locations);
                    if (location) {
                        const foundLocation = find(foundFloor.locations, (l: Ilocation) => {
                            return l.id === location.value;
                        });
                        if (foundLocation) {
                            roomOptions = FormUtil.convertToOptions(foundLocation.rooms);
                        }
                    }
                }
            }
        }

        const measurementPointListResultStatusOptions = [...FormUtil.convertEnumToOptions(inventoryStatusFilterEnum)];
        const workOrderResultStatusOptions = FormUtil.convertEnumToOptions(workOrderStatusEnum).map(item => {
            return {
                value: item.value,
                label: this.props.t('manageWorkOrder:' + item.label)
            };
        });

        let mainSearchControls = {
            building: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:building',
                    options: buildingOptions,
                    colWidth: 5,
                    colWidthLarge: 4,
                    type: 'text',
                    placeholder: 'searchPlaceholder',
                    isClearable: true
                },
                formState: { value: building, disabled }
                // TODO figure out how to pre-select unallocated
                // formState: { value: building || defaultBuildingID, disabled }
            },
            floor: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'floor',
                    colWidth: 4,
                    colWidthLarge: 4,
                    placeholder: 'common:searchPlaceholder',
                    options: floorOptions,
                    isClearable: true
                },
                formState: { value: floor, disabled }
            }
        } as { [key: string]: GroupProps };

        let additionalSearchControls = {
            location: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'location',
                    colWidth: 4,
                    colWidthLarge: 2,
                    placeholder: 'common:searchPlaceholder',
                    options: locationOptions,
                    isClearable: true
                },
                formState: { value: location, disabled }
            },
            room: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'room',
                    colWidth: 4,
                    colWidthLarge: 2,
                    placeholder: 'common:searchPlaceholder',
                    options: roomOptions,
                    isClearable: true
                },
                formState: { value: room, disabled }
            },

            measurementPointListResultStatus: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'status',
                    options: measurementPointListResultStatusOptions,
                    colWidth: 4,
                    colWidthLarge: 3,
                    type: 'select',
                    placeholder: 'measurementPointListResultStatusPlaceholder',
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: {
                    value: measurementPointListResultStatus,
                    disabled
                }
            },
            workOrderResultStatus: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'manageWorkOrder:workOrderResultStatus',
                    options: workOrderResultStatusOptions,
                    colWidth: 4,
                    colWidthLarge: 3,
                    type: 'select',
                    placeholder: 'manageWorkOrder:workOrderResultStatusPlaceholder',
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: { value: workOrderResultStatus, disabled }
            },
            mainCategory: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:mainCategory',
                    options: this.props.productInfo.mainCategoryOptions,
                    colWidth: 5,
                    colWidthLarge: 4,
                    type: 'select',
                    placeholder: 'searchPlaceholder',
                    isClearable: true,
                    shouldTranslate: false
                },
                formState: { value: mainCategory, disabled }
            },
            search: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'search',
                    colWidth: 4,
                    type: 'input',
                    placeholder: 'tableFilters.searchPlaceholder',
                    name: 'asset-search'
                },
                formState: { value: search, disabled }
            },
            system: {
                render: FormUtil.Select,
                meta: {
                    options: constants.constructSystems(this.props.t),
                    label: 'manageInventory:system',
                    colWidth: 4,
                    placeholder: 'nsJob:typePlaceholder',
                    name: 'system',
                    disableSort: true,
                    isClearable: true
                },
                formState: { value: system, disabled }
            },
            originalMeasurementPointListResultStatus: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: measurementPointListResultStatusOptions.map(({ value, label }) => ({
                        value: value,
                        label: this.props.t('manageMeasurementPointLists:' + label)
                    })),
                    label: 'status',
                    colWidth: 4,
                    placeholder: 'Search',
                    name: 'originalMeasurementPointListResultStatus',
                    isClearable: true
                },
                formState: { value: undefined, disabled }
            },
            hideVirtualToggle: {
                render: FormUtil.Toggle,
                meta: {
                    label: 'Hide Virtual',
                    colWidth: 4,
                    defaultValue: true,
                    innerStyle: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                    style: {
                        marginTop: '2%'
                    }
                },
                formState: { value: true, disabled } // this actually sets the value to true in the form state
            }
        } as { [key: string]: GroupProps };

        // which status select
        if (this.props.isAddWorkOrderMode === true) {
            additionalSearchControls = omit(additionalSearchControls, [
                'workOrderResultStatus',
                'measurementPointListResultStatus'
            ]);
        } else {
            mainSearchControls = omit(mainSearchControls, ['$field_1']);
            additionalSearchControls = omit(additionalSearchControls, ['originalMeasurementPointListResultStatus']);
        }

        if (this.props.isWorkOrderTypeJob) {
            additionalSearchControls = omit(additionalSearchControls, 'measurementPointListResultStatus');
        } else {
            additionalSearchControls = omit(
                additionalSearchControls,
                'workOrderResultStatus',
                'originalMeasurementPointListResultStatus'
            );
        }

        if (this.props.selectedJob.jobTypeID !== jobTypesIdEnum.agsRebalancing) {
            additionalSearchControls = omit(additionalSearchControls, 'system');
        }

        this.props.setTableFilter({
            building,
            floor,
            location,
            room,
            measurementPointListResultStatus,
            workOrderResultStatus,
            mainCategory,
            showAdditionalFilters,
            search,
            system,
            hideVirtualToggle: true
        });

        if (showAdditionalFilters) {
            return {
                controls: {
                    ...mainSearchControls,
                    ...additionalSearchControls
                }
            };
        } else {
            return { controls: { ...mainSearchControls } };
        }
    };

    debouncedSetTableFilter = debounce((key, value) => {
        this.props.setTableFilter({ [key]: value, page: 0 });
    }, 500);

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'building':
                this.filterFloors(value);
                break;
            case 'location':
                this.filterRooms(value);
                break;
            case 'floor':
                this.filterLocations(value);
                break;
            case 'search':
                this.debouncedSetTableFilter(key, value);

                break;
            default:
                this.props.setTableFilter({ [key]: value, page: 0 });
                break;
        }
    };

    filterFloors = (value: Ioption | null) => {
        if (value && value.value) {
            const buildingID = value.value;
            const foundBuilding = find(this.props.buildings, (b: Ibuilding) => {
                return b.id === buildingID;
            });
            let newFloorOptions: any[] = [];

            if (foundBuilding) {
                newFloorOptions = FormUtil.convertToOptions(foundBuilding.floors);
            }
            const floorControl = this.searchForm.get('floor') as AbstractControlEdited;
            floorControl.meta.options = newFloorOptions;
            floorControl.stateChanges.next();
        }
        const locationControl = this.searchForm.get('location') as AbstractControlEdited;
        if (locationControl) {
            locationControl.meta.options = [];
            locationControl.stateChanges.next();
        }
        const roomControl = this.searchForm.get('room') as AbstractControlEdited;
        if (roomControl) {
            roomControl.meta.options = [];
            roomControl.stateChanges.next();
        }
        this.searchForm.patchValue({ floor: null });
        this.searchForm.patchValue({ location: null });
        this.searchForm.patchValue({ room: null });
        this.props.setTableFilter({
            page: 0,
            floor: null,
            location: null,
            room: null,
            building: value
        });
    };

    filterLocations = (value: Ioption | null) => {
        if (value === null && (this.props.tableFilters.floor === null || this.props.tableFilters.floor === undefined)) {
            return;
        }

        const { building } = this.searchForm.value;
        if (building && value && value.value) {
            const floorID = value.value;
            const foundBuilding = find(this.props.buildings, (b: Ibuilding) => {
                return b.id === building.value;
            });
            let newLocationOptions: any[] = [];

            if (foundBuilding) {
                const floor = find(foundBuilding.floors, (f: Ifloor) => {
                    return f.id === floorID;
                });
                if (floor) {
                    newLocationOptions = FormUtil.convertToOptions(floor.locations);
                }
            }

            const locationControl = this.searchForm.get('location') as AbstractControlEdited;
            if (locationControl) {
                locationControl.meta.options = newLocationOptions;
                locationControl.stateChanges.next();
            }
        }
        // Reset other selects
        const roomControl = this.searchForm.get('room') as AbstractControlEdited;
        if (roomControl) {
            roomControl.meta.options = [];
        }
        this.searchForm.patchValue({ room: null });
        this.searchForm.patchValue({ location: null });
        this.props.setTableFilter({
            page: 0,
            floor: value,
            location: null,
            room: null
        });
    };

    filterRooms = (value: Ioption | null) => {
        if (
            value === null &&
            (this.props.tableFilters.location === null || this.props.tableFilters.location === undefined)
        ) {
            return;
        }
        const { building, floor } = this.searchForm.value;
        if (building && floor && value && value.value) {
            const locationID = value.value;
            const foundBuilding = find(this.props.buildings, (b: Ibuilding) => {
                return b.id === building.value;
            });
            let newRoomOptions: any[] = [];

            if (foundBuilding) {
                const foundFloor = find(foundBuilding.floors, (f: Ifloor) => {
                    return f.id === floor.value;
                });
                if (foundFloor) {
                    const loc = find(foundFloor.locations, (l: Ilocation) => {
                        return l.id === locationID;
                    });
                    if (loc) {
                        newRoomOptions = FormUtil.convertToOptions(loc.rooms);
                    }
                }
            }
            const roomControl = this.searchForm.get('room') as AbstractControlEdited;
            roomControl.meta.options = newRoomOptions;
            roomControl.stateChanges.next();
        }
        // set to null outside of the if, because we might receive null as the value
        this.searchForm.patchValue({ room: null });
        this.props.setTableFilter({
            page: 0,
            location: value,
            room: null
        });
    };

    handleToggleAdditionalFilters = () => {
        this.props.setTableFilter({
            showAdditionalFilters: !this.props.tableFilters.showAdditionalFilters
        });
    };

    hasActiveAdditionalFilters = () => {
        const { mainCategory } = this.props.tableFilters;
        if (mainCategory) {
            return true;
        } else {
            return false;
        }
    };

    handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.handleSubmit(this.searchForm.value);
    };

    setForm = (form: AbstractControl) => {
        this.searchForm = form;
        this.searchForm.meta = {
            loading: this.props.loading
        };
        this.subscribeToValueChanges();
    };

    render() {
        return (
            <Row className="search-table-form beacon-form" style={{}}>
                <form onSubmit={this.handleSubmit}>
                    <Col xs={this.props.isAddWorkOrderMode ? 12 : 9} lg={10} style={{ paddingLeft: '0px' }}>
                        <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                    </Col>
                    {this.props.isAddWorkOrderMode && (
                        <Col xs={3} style={{ marginTop: '5px' }}>
                            <Button
                                onClick={this.props.startAddWorkOrders}
                                disabled={this.props.hasSelection === false}
                                title={this.props.hasSelection ? 'add' : 'must select at least one'}
                            >
                                {this.props.t('addSelectedAssetToJob')}
                            </Button>
                        </Col>
                    )}
                    {this.props.isAddWorkOrderMode && (
                        <Col xs={2} style={{ marginTop: '5px' }}>
                            <Button
                                onClick={this.props.resetSelection}
                                disabled={this.props.hasSelection === false}
                                title={this.props.hasSelection ? 'reset' : 'must select at least one'}
                            >
                                {this.props.t('resetSelectionButton')}
                            </Button>
                        </Col>
                    )}
                    <Col
                        xs={1}
                        lg={1}
                        style={{
                            marginTop: this.props.isAddWorkOrderMode ? '0px' : '18px'
                        }}
                    >
                        <Button
                            bsStyle="link"
                            style={{
                                fontSize: '2em',
                                color: this.hasActiveAdditionalFilters() ? constants.colors.orange : ''
                            }}
                            onClick={this.handleToggleAdditionalFilters}
                        >
                            <FontAwesomeIcon
                                icon={
                                    this.props.tableFilters.showAdditionalFilters
                                        ? ['far', 'angle-double-down']
                                        : ['far', 'angle-double-right']
                                }
                            />{' '}
                        </Button>
                    </Col>

                    {this.props.isAddWorkOrderMode === false && (
                        <InventoryActionButtonContainer
                            t={this.props.t}
                            facility={this.props.facility}
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                            installBasesPopulated={this.props.installBasesPopulated}
                            colorButton={this.props.colorButton}
                            resetSelection={this.props.resetSelection}
                        />
                    )}

                    {this.showBtn && (
                        <Col xs={1} className="search-form-button">
                            <Button
                                bsStyle={this.props.colorButton}
                                bsSize="sm"
                                type="submit"
                                disabled={this.props.loading}
                            >
                                <FontAwesomeIcon icon="search" />
                            </Button>
                        </Col>
                    )}
                </form>
            </Row>
        );
    }
}
