import {
    Itile,
    Iuser,
    transitionInType,
    transitionOutType,
    Icountry,
    IoptionNumberValue,
    Ioption,
    ImainFailureCodes,
    IsubFailureCodes,
    IreasonFailureCodes,
    IlaborRate
} from '../models';
import { MSAL_SCOPES } from '../components/auth/Auth-Utils';
import msalInstance from '../components/auth/MsalInstance';
import { emptyTile } from '../reducers/initialState';
import { find } from 'lodash';
import { jobTypesIdEnum, jobTypesIdEnumForSelect, measurementPointListTypeEnum } from '../models-enums';
import { toastr } from 'react-redux-toastr';
import { TFunction } from 'i18next';
import { securityFunctions } from './securityFunctions';
import { FormUtil } from '../components/common/FormUtil';

const jobCommentTypeOptions = [
    { value: true, label: 'Deficiency' },
    { value: false, label: 'Info' }
];

const measurementPointTypeOptions = [
    { label: 'Pass/Fail', value: 1 },
    { label: 'Text', value: 2 },
    { label: 'Numeric', value: 3 },
    { label: 'Select', value: 4 },
    { label: 'LongText', value: 7 }
];

const measurementPointListTypeOptions = [
    { label: 'Annual', value: measurementPointListTypeEnum.annual },
    { label: 'Verification', value: measurementPointListTypeEnum.verification }
];
const measurementPointPassFailOptions = [
    { label: 'Pass', value: 1 },
    { label: 'Fail', value: 2 },
    { label: 'Not Applicable', value: 3 }
];

const jobStatusOptions = [
    { value: false, label: 'Active' },
    { value: true, label: 'Completed' }
];
const icons = {
    dashboard: require('../images/icons/BM_Dashboard.png'),
    inventory: require('../images/icons/BM_Inventory.png'),
    manageJobs: require('../images/icons/BM_ManageJobs.png'),
    manageTeam: require('../images/icons/BM_ManageTeam.png'),
    manageUsers: require('../images/icons/BM_ManageUsers.png'),
    productQueue: require('../images/icons/BM_NewProductQueue.png'),
    userQueue: require('../images/icons/BM_NewUserQueue.png'),
    payments: require('../images/icons/BM_Payments.png'),
    training: require('../images/icons/BM_Training.png'),
    locations: require('../images/icons/BM_ManageLocations.png')
};

const colors = {
    green: '#7FC467',
    greenButton: 'success', // use the bootstrap success button color
    greenTr: 'rgba(127, 196, 103,.2)',
    greenBanner: 'rgba(127, 196, 103, .85)',
    blue: '#027AC3',
    teal: '#0c5a8b',
    blueTr: 'rgba(2,122,195,.2)',
    blueBanner: 'rgba(2,122,195,.85)',
    blueButton: 'primary',
    darkButton: 'primary',
    greyButton: 'default',
    orange: '#FD9A24',
    orangeTr: 'rgba(255,166,99,.2)',
    orangeBanner: 'rgba(255,166,99,.85)',
    orangeButton: 'warning',
    lightTeal: '#06B6B6',
    purple: '#06B6B6',
    purpleBanner: 'rgba(6, 182, 182, .85)',
    purpleButton: 'info', // use the bootstrap info button color
    purpleTr: 'rgba(6, 182, 182,.2)',
    lightTealBanner: 'rgba(6, 182, 182, .85)',
    dark: '#013564',
    darkBanner: 'rgba(1, 53, 100, .85)',
    red: '#d00000',
    redTr: 'rgba(208, 0, 0, 0.2)',
    grey: '#A2ACB4',
    greyBanner: 'rgba(162, 172, 180,.85)',
    greyText: '#AAAAAA',
    darkGreyText: '#888888',
    darkRed: '#840000',
    darkGrey: '#707070',
    greyBlueShadow: '#3b5161',
    textBlue: '#007AC3',
    greyBlue: '#7094ad',
    darkerBlue: '#306BA0'
};

const tiles: Itile[] = [
    {
        icon: 'icon-alerts',
        title: 'alerts',
        src: 'https://placekitten.com/360/136',
        srcBanner: '',
        color: 'dark',
        width: 360,
        height: 136,
        url: '/alerts',
        securityFunction: '',
        description: ''
    },
    {
        icon: icons.manageJobs,
        title: 'FSE',
        src: require('../images/beaconManageJobs.jpg'),
        srcBanner: require('../images/beaconManageJobsHeader.jpg'),
        color: 'purple',
        width: 360,
        height: 400,
        url: '/jobs',
        securityFunction: securityFunctions.FSE.id,
        description: ''
    },
    {
        icon: icons.manageJobs,
        title: 'completedJobs',
        src: require('../images/beaconManageJobs.jpg'),
        srcBanner: require('../images/beaconManageJobsHeader.jpg'),
        color: 'orange',
        width: 360,
        height: 400,
        url: '/completed_jobs',
        securityFunction: securityFunctions.ManageJobs.id,
        description: ''
    },
    {
        icon: icons.manageJobs,
        title: 'manageJobComments',
        src: require('../images/beaconManageJobs.jpg'),
        srcBanner: require('../images/beaconManageJobsHeader.jpg'),
        color: 'green',
        width: 360,
        height: 400,
        url: '/job_comments',
        securityFunction: securityFunctions.FSE.id,
        description: ''
    },
    {
        icon: icons.locations,
        title: 'locations',
        src: require('../images/beaconManageUsers.jpg'),
        srcBanner: require('../images/beaconManageUsersHeader.jpg'),
        color: 'orange',
        width: 360,
        height: 400,
        url: '/locations',
        // securityFunction: securityFunctions.ManageLocations.id,
        securityFunction: securityFunctions.FSE.id,
        description: ''
    },
    {
        icon: icons.inventory,
        title: 'inventory',
        src: require('../images/beaconManageInventory.jpg'),
        srcBanner: require('../images/beaconManageInventoryHeader.jpg'),
        color: 'blue',
        width: 360,
        height: 400,
        url: '/devices',
        securityFunction: securityFunctions.ViewInventory.id,
        description: ''
    },
    {
        icon: icons.inventory,
        title: 'simple list',
        src: require('../images/beaconManageInventory.jpg'),
        srcBanner: require('../images/beaconManageInventoryHeader.jpg'),
        color: 'blue',
        width: 360,
        height: 400,
        url: '/simple_list',
        securityFunction: securityFunctions.ViewInventory.id,
        description: ''
    },
    {
        icon: icons.inventory,
        title: 'repair',
        src: require('../images/beaconManageInventory.jpg'),
        srcBanner: require('../images/beaconManageInventoryHeader.jpg'),
        color: 'green',
        width: 360,
        height: 400,
        url: '/repair',
        securityFunction: securityFunctions.ViewInventory.id,
        description: ''
    },
    {
        icon: icons.inventory,
        title: 'warranty',
        src: require('../images/beaconManageInventory.jpg'),
        srcBanner: require('../images/beaconManageInventoryHeader.jpg'),
        color: 'green',
        width: 360,
        height: 400,
        url: '/warranty',
        securityFunction: securityFunctions.ViewInventory.id,
        description: ''
    },
    {
        icon: icons.inventory,
        title: 'maintenance',
        src: require('../images/beaconManageInventory.jpg'),
        srcBanner: require('../images/beaconManageInventoryHeader.jpg'),
        color: 'orange',
        width: 360,
        height: 400,
        url: '/maintenance',
        securityFunction: securityFunctions.ViewInventory.id,
        description: ''
    },
    {
        icon: icons.inventory,
        title: 'suggestedParts',
        src: require('../images/beaconManageInventory.jpg'),
        srcBanner: require('../images/beaconManageInventoryHeader.jpg'),
        color: 'orange',
        width: 360,
        height: 400,
        url: '/suggested_parts',
        securityFunction: securityFunctions.FSE.id,
        description: ''
    },
    {
        icon: icons.inventory,
        title: 'quotePartCart',
        src: require('../images/beaconManageInventory.jpg'),
        srcBanner: require('../images/beaconManageInventoryHeader.jpg'),
        color: 'orange',
        width: 360,
        height: 400,
        url: '/quote_part_cart',
        securityFunction: securityFunctions.FSE.id,
        description: ''
    },
    {
        icon: icons.inventory,
        title: 'sap',
        src: require('../images/beaconManageInventory.jpg'),
        srcBanner: require('../images/beaconManageInventoryHeader.jpg'),
        color: 'orange',
        width: 360,
        height: 400,
        url: '/sap',
        securityFunction: securityFunctions.FSE.id,
        description: ''
    },
    {
        icon: icons.inventory,
        title: 'unassignedSapWorkorders',
        src: require('../images/beaconManageInventory.jpg'),
        srcBanner: require('../images/beaconManageInventoryHeader.jpg'),
        color: 'orange',
        width: 360,
        height: 400,
        url: '/unassigned-sap-workorders',
        securityFunction: securityFunctions.FSE.id,
        description: ''
    },
    {
        icon: icons.inventory,
        title: 'commission',
        src: require('../images/beaconManageInventory.jpg'),
        srcBanner: require('../images/beaconManageInventoryHeader.jpg'),
        color: 'blue',
        width: 360,
        height: 400,
        url: '/commission',
        securityFunction: securityFunctions.FSE.id,
        description: ''
    }
];

const coverLetter = `<p><strong>Scope of Work</strong>: ENTER SCOPE OF WORK</p>
<p><strong>Verified to Category Level</strong>: ENTER CATEGORY LEVEL&nbsp;</p>
<p><strong>Type</strong>: ENTER RENOVATION / NEW CONSTRUCTION / REPAIR</p>
<p><strong>Contractor Certification</strong>&nbsp;</p>
<ol>
  <li>The complete installation complies with NFPA 99 (2015 edition)</li>
  <li>Material and installation comply with section 5.1.10.</li>
  <li>Installer performance testing completed and complies with sections 5.1.12.1 through 5.1.12.2.7.7</li>
</ol>
<p>In accordance with the contract documents and scope of work noted above, and the applicable sections of NFPA 99 (2015 edition), it is hereby verified that the Medical Gas/Vacuum Equipment referenced herein</p>
<p><strong>ENTER MEETS OR DOES NOT MEET the requirements of NFPA 99, 2015 Edition</strong></p>`;
export const constants = {
    toastrError: {
        transitionIn: 'bounceInDown' as transitionInType,
        transitionOut: 'bounceOutUp' as transitionOutType,
        timeOut: 8000
    },
    toastrWarning: {
        transitionIn: 'bounceInDown' as transitionInType,
        transitionOut: 'bounceOutUp' as transitionOutType,
        timeOut: 8000
    },
    toastrSuccess: {
        transitionIn: 'bounceInDown' as transitionInType,
        transitionOut: 'bounceOutUp' as transitionOutType,
        timeOut: 3000
    },
    colors,
    coverLetter,
    securityFunctions,
    tiles,
    icons,
    jobCommentTypeOptions,
    measurementPointTypeOptions,
    measurementPointPassFailOptions,
    jobStatusOptions,
    measurementPointListTypeOptions,
    defaultProductStandardID: '740e2f29-6bfa-4316-98c1-f2b32637bf6e',
    hasSecurityFunction: (user: Iuser, securityFunctionID: string): boolean => {
        const SFs = user.securityFunctions.map(sf => sf.toUpperCase());
        if (SFs.indexOf(securityFunctionID.toUpperCase()) >= 0) {
            return true;
        } else {
            return false;
        }
    },
    getTileByURL: (URL: string): Itile => {
        const firstParam = '/' + URL.split('/')[1];
        const foundTile = find(tiles, { url: firstParam });
        if (foundTile && foundTile.url.length) {
            return foundTile as Itile;
        } else {
            return emptyTile;
        }
    },
    countErrors: 0,
    countries: require('../constants/countries.json') as {
        [key: string]: Icountry;
    },
    mainFailureCodes: require('../constants/mainFailureCodes.json') as ImainFailureCodes[],
    subFailureCodes: require('../constants/subFailureCodes.json') as IsubFailureCodes[],
    reasonFailureCodes: require('../constants/failureCodes.json') as IreasonFailureCodes[],
    handleError(error: any, message: string, level: 'warning' | 'error' | 'default' = 'default', showToastr = true) {
        let msg = '';
        if (error && error.response && error.response.data) {
            // get error message from the JSON body
            const messageDetail =
                error.response.data instanceof Object && error.response.data.hasOwnProperty('value')
                    ? error.response.data.value
                    : error.response.data;
            msg = `Failed to ${message}. ${messageDetail}`;
        } else if (error && error.message) {
            // get error message from the error.message
            msg = `Failed to ${message}.  Please try again or contact support. ${error.message}`;
        } else {
            // show an error without any additional details
            msg = `Failed to ${message}.  Please try again or contact support.`;
        }
        if (!navigator.onLine) {
            // override and show an error if we are not connected to the internet
            msg = 'Please connect to the internet.';
        }

        if (error && error.errorCode === 'user_login_error') {
            // override login errors and do not show an error
            setTimeout(() => {
                msalInstance.msalApp.loginRedirect({
                    scopes: [MSAL_SCOPES.MMG]
                });
            }, 1000);
            return;
        }

        if (error && error.response && error.response.status === 401) {
            console.error(
                '[handleError]: catching unauthorized, we should not get here now that we are using msalFetch'
            );
            return; // don't show an error
        }
        // show friendly timeout errors
        // if (error.code === 'ECONNABORTED') {
        //     msg = 'MyMedGas is having trouble connecting to the internet. Please refresh and try again.';
        //     // dispatch an event letting the app know a request timmed out
        //     document.dispatchEvent(new CustomEvent('httpTimeout'));
        // }

        if (showToastr) {
            if (level === 'warning') {
                toastr.warning('Warning', message, constants.toastrWarning);
            } else if (level === 'error') {
                toastr.error('Error', message, constants.toastrError);
            } else {
                toastr.error('Error', msg, constants.toastrError);
            }
        }
    },

    constructSystems: (t: TFunction): IoptionNumberValue[] => {
        let systemsOptions: IoptionNumberValue[] = [];
        for (let i = 1; i < 41; i++) {
            systemsOptions = [
                ...systemsOptions,
                {
                    label: t('manageInventory:systemsOptionLabel', {
                        count: i
                    }),
                    value: i
                }
            ];
        }
        return systemsOptions;
    },

    formDebounceTime: 300,
    tableSearchDebounceTime: 1000,
    searchProductPageCount: 80, // max items to show inside the product search modal
    searchProductRecentProductLimit: 30,
    tablePageSizeDefault: 25,
    tablePageSizeOptions: [10, 25, 50, 100],
    httpTimeout: 1000 * 60,
    httpTimeoutInitialSync: 1000 * 60 * 10, // 10 minutes
    httpOfflineConfirmCount: 3,
    httpOfflineConfirmCountResetTimeout: 1000 * 30,
    maxDaysBetweenFullSync: 5, // how many days can you go without syncing before the system will do a full sync rather than call /latest
    numberOfDaysForNotTestedIn12M: 300,
    bulkSelectionLimit: 5000,
    bulkSelectionPassLimit: 800,
    cacheTranslationsTimeout: 60 * 60 * 1000 * 2, // 2 hours,
    secondsBetweenLatestUpdate: 60,
    cacheCustomersMinutes: 10,
    cacheAllPartsMinutes: 30,
    momentSQLFormat: 'YYYY-MM-DDTHH:mm:ss', // not being used YET
    momentDisplayFormat: 'DD-MMM-YY', // not being used YET see TODO in formUtil
    defaultStandardID: '740e2f29-6bfa-4316-98c1-f2b32637bf6e',
    virtualProductSubcategoryID: 'cad4e18c-45d6-4e42-8a27-090bf385e723',
    usCountryID: 'abc5d95c-129f-4837-988c-0bf4ae1f3b67',
    ukCountryID: '419ec375-dce2-41fb-8251-ce28caa507f5',
    germanyCountryID: '049060ff-8fc7-44b3-9c0e-20a987aac7ff',
    caCountryID: '2fcbfdc4-9c25-4b58-874c-7c2625eaa750',
    malaysiaCountryID: '0b61d1dd-f32e-442c-9ff4-fb5fc1e012e4',
    defaultCountryID: 'abc5d95c-129f-4837-988c-0bf4ae1f3b67'
};

export const postalAndStateRequiredCountries = {
    [constants.usCountryID]: { postalCode: true, state: true },
    [constants.ukCountryID]: { postalCode: true, state: false },
    [constants.germanyCountryID]: { postalCode: true, state: false },
    [constants.caCountryID]: { postalCode: true, state: true },
    [constants.malaysiaCountryID]: { postalCode: false, state: false }
};

export const originOptions: Ioption[] = [
    { value: 'USN', label: 'USN' },
    { value: 'GBN', label: 'GBN' },
    { value: 'CAN', label: 'CAN' }
];

export const productPlaceholderImages = [
    {
        categoryId: 'C17EA749-20B7-4AB7-AB15-237A85A92E83',
        src: 'AGS System_D2.png'
    },
    {
        categoryId: 'AEC75F68-6836-4AF1-89CF-75D9232A8D7B',
        src: 'Medical Air Systems (Air).png'
    },
    {
        categoryId: 'AEE5775D-FD2D-4831-9D68-80090676EF5F',
        src: 'Monitoring.png'
    },
    {
        categoryId: '3C4D2DC0-0CCD-4281-861D-F07BCCA8F6F7',
        src: 'Monitoring.png'
    },
    {
        categoryId: '6E817F59-CF14-42ED-89C9-9E99C15E6CA3',
        src: 'Monitoring.png'
    },
    {
        categoryId: '699C584F-F431-432B-A251-FF8E3DC82D96',
        src: 'Patient Environment-01.png'
    },
    {
        categoryId: 'EA5E3BBE-9D11-4F8A-B9BA-8263829B1BF4',
        src: 'Valves.png'
    },
    {
        categoryId: '55EC8036-F4CB-4FC9-8ED7-87EAAED8DF13',
        src: 'manifold.png'
    },
    {
        categoryId: '59D4F580-40A8-4084-A2C3-AE5B08C9718E',
        src: 'Gas outlets.png'
    },
    {
        categoryId: '97BAE3C8-1ADC-4429-8BF0-68F47618CD03',
        src: 'Medical Vacuum-01.png'
    },
    {
        categoryId: '0C3C9A37-5B98-4B7D-8141-384747DA373C',
        src: 'Valves.png'
    },
    {
        categoryId: 'A142693B-0272-4673-98FF-B76DB5814023',
        src: 'Valves.png'
    },
    {
        categoryId: '5CD2F63A-2219-42CF-B613-52C24067B2E9',
        src: '06-Liquid Oxygen System (Oxygen).png'
    },
    {
        categoryId: 'A8C77FFB-134A-412D-B763-311E18255CE5',
        src: 'Medical Pipeline-01.png'
    },
    {
        categoryId: '1A28BE85-84AB-40BD-840F-E32DCFFAAD4C',
        src: '08-Oxygen Concentrator Systems (Oxygen).png'
    },
    {
        categoryId: 'F045DE0B-0B30-4D57-BBAD-EF5C54922B81',
        src: '01-Pipeline Components.png'
    }
];

export const jobTypeOptions = FormUtil.convertEnumToOptions(
    Object.keys(jobTypesIdEnumForSelect)
        .filter(
            key =>
                jobTypesIdEnumForSelect[key as keyof typeof jobTypesIdEnumForSelect] !==
                jobTypesIdEnumForSelect.nonProductive
        )
        .reduce((obj, key) => {
            obj[key as keyof typeof jobTypesIdEnumForSelect] =
                jobTypesIdEnumForSelect[key as keyof typeof jobTypesIdEnumForSelect];
            return obj;
        }, {} as { [key: string]: string })
);

export const jobTypesForSAPWOs = [
    jobTypesIdEnum.repair,
    jobTypesIdEnum.commissioning,
    jobTypesIdEnum.inspection,
    jobTypesIdEnum.warrantyBM,
    jobTypesIdEnum.servicePlan,
    jobTypesIdEnum.audit,
    jobTypesIdEnum.agsRebalancing,
    jobTypesIdEnum.servicePlan,
    jobTypesIdEnum.verification,
    jobTypesIdEnum.maintenance
];

export const productMainCategories = {
    agss: 'C17EA749-20B7-4AB7-AB15-237A85A92E83',
    outlet: '59D4F580-40A8-4084-A2C3-AE5B08C9718E',
    other: 'F045DE0B-0B30-4D57-BBAD-EF5C54922B81'
};

export const capitalEquipmentCategories = {
    agss: 'c17ea749-20b7-4ab7-ab15-237a85a92e83',
    airSystem: 'aec75f68-6836-4af1-89cf-75d9232a8d7b',
    manifold: '55ec8036-f4cb-4fc9-8ed7-87eaaed8df13',
    oxygenSystem: '1a28be85-84ab-40bd-840f-e32dcffaad4c',
    vacuumWagd: '97bae3c8-1adc-4429-8bf0-68f47618cd03'
};

export const subCategories = {
    switchSensor: 'CA6CE9C0-4295-48C0-8BD0-A592C0D23C11'
};

export const productTypes = {
    agssWAGD: '7C0B3558-80CC-4ECE-8E73-C3E7FD1175D5'
};

export const daysUntilJobIsOverdue = 30;

// This is not all, only really need this Job one right now
export const mainCategories: { [key: string]: string } = {
    job: 'ee4e20f7-2541-414e-8810-dd53dc3064ea'
};

export const countriesWithJobDefaults = [constants.usCountryID, constants.caCountryID];

export const sapBusinessIndicators = {
    fixedPrice: 'FP',
    servicePlan: 'SP',
    mf: 'MF',
    fm: 'FM'
};

export const appSyncItemNames = {
    Products: 'Products',
    ProductInfo: 'ProductInfo',
    FSEUsers: 'FSEUsers',
    MeasurementPointLists: 'MeasurementPointLists',
    JobCommentCodes: 'JobCommentCodes',
    Jobs: 'Jobs',
    NonProductiveHours: 'NonProductiveHours',
    NextJobNumber: 'NextJobNumber',
    SAPMapping: 'SAPMapping',
    LaborRates: 'LaborRates'
};
