/*
 * EditJobForm
 * Edit existing users
 */

import * as React from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import { Ifacility, Ijob, Iuser } from '../../models';
import { jobSourceEnum, jobStatusEnum, jobTypesIdEnum, jobTypesIdEnumInverse } from '../../models-enums';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { RouteComponentProps } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { setIsDownloadingJob } from '../../actions/commonActions';
import { setTableFilter, toggleModalSignaturePad } from '../../actions/manageInventoryActions';
import { toggleViewJobModal } from '../../actions/manageJobActions';
import { getJobComments } from '../../actions/manageJobCommentsActions';
import {
    getJobSignatures,
    getSAPWorkOrders,
    toggleIsCollectingSignatures,
    toggleJobSignatureModal
} from '../../actions/workOrderActions';
import { getMeasurementPointListResultsForJobsHelper } from '../../actions/workerActions';
import { constants } from '../../constants/constants';
import { initialJob } from '../../reducers/initialState';

interface Iprops extends RouteComponentProps<{}> {
    selectedJob: Ijob;
    loading: boolean;
    colorButton: string;
    isJobDownloaded: boolean;
    toggleViewJobModal: typeof toggleViewJobModal;
    getJobComments: typeof getJobComments;
    getJobSignatures: typeof getJobSignatures;
    getInventory: (facilityID: string, skipQueue?: boolean) => Promise<any>;
    ensureLatestProducts: () => Promise<any>;
    user: Iuser;
    reopenJob: (job: Ijob) => Promise<any>;
    facility: Ifacility;
    openJob: (jobID: string) => Promise<any>;
    isJobSignatureModalOpen: boolean;
    isCollectingSignatures: boolean;
    isSignatureModalOpen: boolean;
    toggleJobSignatureModal: typeof toggleJobSignatureModal;
    toggleIsCollectingSignatures: typeof toggleIsCollectingSignatures;
    toggleModalSignaturePad: typeof toggleModalSignaturePad;
    getSAPWorkOrders: typeof getSAPWorkOrders;
    getMeasurementPointListResultsForJobsHelper: typeof getMeasurementPointListResultsForJobsHelper;
    setIsDownloadingJob: typeof setIsDownloadingJob;
    setTableFilter: typeof setTableFilter;
}

class ViewJobForm extends React.Component<Iprops & WithTranslation, {}> {
    static defaultProps = {
        selectedJob: initialJob
    };

    componentDidMount() {
        if (!this.props.selectedJob) {
            console.error('[componentDidMount]: missing job');
            return;
        }
    }

    closeOpenModals() {
        if (this.props.isJobSignatureModalOpen) {
            this.props.toggleJobSignatureModal();
        }
        if (this.props.isSignatureModalOpen) {
            this.props.toggleModalSignaturePad();
        }
        if (this.props.isCollectingSignatures) {
            this.props.toggleIsCollectingSignatures();
        }
    }

    showReopenJobButton = () => {
        return this.props.selectedJob.status === jobStatusEnum.completed;
    };

    clearManageInventoryFilters = () => {
        this.props.setTableFilter({
            search: '',
            page: 0,
            building: undefined,
            floor: undefined,
            room: undefined,
            location: undefined,
            status: undefined,
            workOrderResultStatus: undefined,
            measurementPointListResultStatus: undefined,
            mainCategory: undefined,
            showAdditionalFilters: false,
            system: undefined,
            hideVirtualToggle: true
        });
    };

    handleStart = () => {
        this.clearManageInventoryFilters();
        this.props.toggleViewJobModal();
        this.closeOpenModals();
        this.props.setIsDownloadingJob(true);
        this.props.openJob(this.props.selectedJob.id);

        this.props.getInventory(this.props.selectedJob.facilityID, true).then(() => {
            this.props.getMeasurementPointListResultsForJobsHelper(this.props.selectedJob.id, this.props.facility.id);

            if (!this.props.isJobDownloaded) {
                this.props.getJobComments(this.props.selectedJob);
                this.props.getJobSignatures(this.props.selectedJob.id);
            }

            // SAPWorkOrderModal, triggered from AddHoursModalForm, needs to have SAP Work Orders downloaded
            if (this.props.selectedJob.jobTypeID === jobTypesIdEnum.inspection) {
                this.props.getSAPWorkOrders();
            }

            this.props.ensureLatestProducts();
        });
    };

    handleReOpenJob = () => {
        this.clearManageInventoryFilters();
        this.closeOpenModals();

        const runReOpen = () => {
            this.props.toggleViewJobModal();
            this.props
                .reopenJob(this.props.selectedJob)
                .then(() => {
                    this.props.history.push('/devices');
                    this.props.getJobComments(this.props.selectedJob);
                    this.props.getInventory(this.props.selectedJob.facilityID);
                })
                .catch((error: any) => console.error('[handleReOpenJob]:', error));
        };

        const reOpenBPCSMsg = this.props.t('manageInventory:completeBPCSJobConfirm', {
            source: jobSourceEnum[this.props.selectedJob.source]
        });
        const toastrConfirmOptions = {
            onOk: () => {
                runReOpen();
            },
            onCancel: () => null,
            okText: this.props.t('mobile.viewJobModal.reopenJobButton'),
            cancelText: this.props.t('common:cancel')
        };

        this.props.getJobSignatures(this.props.selectedJob.id);

        if (this.props.selectedJob.source === jobSourceEnum.BPCS) {
            toastr.confirm(reOpenBPCSMsg, toastrConfirmOptions);
        } else {
            runReOpen();
        }
    };

    render() {
        const { selectedJob, t, facility } = this.props;

        if (!selectedJob || !facility) {
            return (
                <Row>
                    <Col xs={12}>
                        <h3>loading...</h3>
                    </Col>
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={this.props.toggleViewJobModal}
                        >
                            {t('mobile.viewJobModal.cancel')}
                        </Button>
                    </Col>
                </Row>
            );
        }

        const { address, address2, city, state, postalCode } = facility;
        const address2WithComma = address2.length ? address2 + ',' : address2; // only add this comma if there is an address2
        const encodedAddress = encodeURIComponent(`${address}, ${address2WithComma} ${city}, ${state}, ${postalCode}`);

        return (
            <div className="job-details-content beacon-form">
                <Row className="form-group">
                    <Col xs={4}>
                        <label className="control-label">{t('mobile.viewJobModal.type')}</label>
                        <p>{t(jobTypesIdEnumInverse[selectedJob.jobTypeID as keyof typeof jobTypesIdEnumInverse])}</p>
                    </Col>
                    <Col xs={8}>
                        <label className="control-label">{t('mobile.viewJobModal.dates')}</label>
                        <p>
                            {moment
                                .utc(selectedJob.startDate)
                                .local(true)
                                .format(constants.momentDisplayFormat)}{' '}
                            -{' '}
                            {moment
                                .utc(selectedJob.endDate)
                                .local(true)
                                .format(constants.momentDisplayFormat)}
                        </p>
                    </Col>
                </Row>
                <Row className="form-group map-button-row">
                    <Col xs={9} className="facility-address">
                        <label className="control-label">{t('mobile.viewJobModal.facility')}</label>
                        <p>{facility.name}</p>
                        <p>{facility.address}</p>
                        {facility.address2 && <p> {facility.address2}</p>}
                        <p>
                            {facility.city}, {facility.state} {facility.postalCode}
                        </p>
                    </Col>
                    <Col xs={3} className="map-button">
                        <Button
                            className="pull-right"
                            bsStyle="default"
                            target="_blank"
                            href={`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`}
                        >
                            <FontAwesomeIcon icon={['far', 'map-marked-alt']} />
                        </Button>
                    </Col>
                </Row>
                <Row className="form-group">
                    <Col xs={12}>
                        <label className="control-label">{t('fseNotes')}</label>
                        <p>{selectedJob.fseNotes}</p>
                    </Col>
                </Row>

                {this.props.selectedJob.isSAPClosed && (
                    <Row className="form-group">
                        <Col xs={12}>
                            <p className="lbl-error">{t('sapClosed')}</p>
                        </Col>
                    </Row>
                )}

                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={this.props.toggleViewJobModal}
                    >
                        {t('mobile.viewJobModal.cancel')}
                    </Button>
                    {!this.props.selectedJob.isSAPClosed && !this.showReopenJobButton() && (
                        <LinkContainer to={'/devices?switch_job=true'} onClick={this.handleStart}>
                            <Button bsStyle={this.props.colorButton} type="button">
                                {this.props.isJobDownloaded
                                    ? t('mobile.viewJobModal.startButton')
                                    : t('mobile.viewJobModal.downloadStartButton')}
                            </Button>
                        </LinkContainer>
                    )}
                    {!this.props.selectedJob.isSAPClosed && this.showReopenJobButton() && (
                        <Button
                            className="pull-right"
                            type="button"
                            bsStyle={this.props.colorButton}
                            onClick={this.handleReOpenJob}
                        >
                            {this.props.isJobDownloaded
                                ? t('mobile.viewJobModal.reopenJobButton')
                                : t('mobile.viewJobModal.downloadReopenJobButton')}
                        </Button>
                    )}
                </Col>
            </div>
        );
    }
}
export default withTranslation('nsJob')(ViewJobForm);
